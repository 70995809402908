import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import { Link } from "gatsby";

const PrivacyPolicy = () => {
  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");
  }, []);

  return (
    <Layout>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                main > .container {
                    padding: 150px 15px 0;
                }
            `,
        }}
      />
      <Head
        pageTitle="Privacy Policy"
        title="Privacy Policy"
        path="/privacy-policy"
        description=""
        imageUrl=""
        type="website"
        datePublished="04/19/2023"
        bodyAttributes={{ class: "d-flex flex-column h-100" }}
      />
      <main class="flex-grow-1 bg-light">
        <div class="container">
          <section>
            <h2 class="text-center mt-0">Privacy Policy</h2>
            <hr class="divider" />
            <p>
              By using this website, you consent to the terms of Eyedog.US'
              Privacy Policy and to Eyedog.US and our employees, agents,
              affiliates, vendors, service providers, partners or contractors'
              processing of personal information for the purposes set forth
              below. This notice is intended to help you understand how we and
              our employees, agents, affiliates, vendors, service providers,
              partners or contractors collect and use your personal data and
              share, store, and protect that data. If at any time you have
              questions about this notice, please let us know by contacting us
              at infosys@intraprise.com.
            </p>
            <h3>Who we are</h3>
            <p>
              Eyedog.US is a division of Intraprise Solutions, Inc.
              (&ldquo;Eyedog&rdquo;).
            </p>
            <h3>Links to third-party sites</h3>
            <p>
              From time to time this website may also include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s). You may not create a link to this website from another
              website or document without Intraprise's prior written consent.
              This notice does not apply to any third-party sites that may link
              to, or be accessible from, our sites. We are not responsible for
              any of the content, features, functionality, or privacy practices
              of other linked sites or services. The data collection and use
              practices of any linked third-party site will be governed by that
              third party's applicable privacy notice, statement, or policy, and
              its terms of use. We encourage you to read them.
            </p>
            <h3>Registration</h3>
            <p>
              You may be required to register in order to be granted access to
              certain information at this website. At the time of registration,
              you may be prompted by one of our online forms to provide certain
              personal information to us, including your name, company name,
              phone number and email address.
            </p>
            <h3>How we use Your Data/Personal Information</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may store, process and use your
              personal data to: send you information; to provide information
              about our company, products or services, including updates,
              notifications, and details of any offers or promotions; to assist
              us in improving our products, services or the content of this
              website; to contact you to participate in a market research
              survey, so that we can gauge customer satisfaction and develop
              better products; or for our own internal marketing and research
              purposes; to promote user groups; to make this website easier to
              use by eliminating the need for you to repeatedly enter the same
              information or by customizing this website or service to your
              particular interests or preferences; to analyze information for
              trends and statistics; to review and respond to proposal
              documents, feedback, comments, photos, videos, or other
              information you submit via online portals, electronic forms,
              surveys, or interactive portions of our sites for our legitimate
              interests and/or to perform a contract with you; administer and
              inform our program strategies and business activities for our
              legitimate interests; administer, safeguard, and improve our
              sites, systems, facilities, events, and other business operations
              for our legitimate interests; protect our rights and the safety of
              others for our legitimate interests; contribute to our archive of
              information for our legitimate interests; and/or comply with
              applicable law, court order, subpoena, or legal process served on
              us to comply with legal obligations. We may also provide this
              information to an employee, affiliate, vendor, service provider,
              partner, contractor or agent acting on Intraprise's behalf in
              connection with the activities described above.
            </p>
            <h3>Additional purposes</h3>
            <p>
              Additional purposes for using your personal data may be described
              in a website, pop-up, banner, click thru text, supplemental
              privacy notice or other communication.
            </p>
            <h3>Data you provide about yourself</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors collect your personal data when
              you voluntarily provide it. For example, you may provide your:
              email address, country of residence, and areas of interest if you
              choose to receive newsletters, updates, or other information from
              us; your contact information, and any other personal data you
              choose to include, if you email, text, or instant message us, or
              contact us through our sites; and any personal data contained in,
              or included with, any proposal documents, feedback, comments,
              photos, videos, or other information you submit via online
              portals, forms, surveys, or interactive portions of our sites.
            </p>
            <p>
              It is always your choice whether to provide this personal data.
              However, some personal data must be provided to participate in
              certain programs, activities, or events (such as to sign up for a
              newsletter, apply for a job, or register to participate in one of
              our events), so the decision not to provide information might
              limit or eliminate such functions of our sites or your ability to
              participate in such programs, activities, or events. Please do not
              disclose more personal data than is requested.
            </p>
            <h3>Personal data you provide about others</h3>
            <p>
              Do not provide personal data about others unless you are
              authorized or required to do so by contract or applicable law. You
              may provide personal data on behalf of another person if you have
              provided them with a copy of this notice and any applicable
              supplemental privacy notice, and obtained their explicit consent.
              We may ask you to provide evidence of that notice and consent.
            </p>
            <h3>Data we receive from third parties and other sources</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may receive personal data about
              you, your company or employer from other sources, including your
              company/organization, contacts, professional references, publicly
              available sources, third-party analytics providers, and other
              third parties. For example, we may receive your personal data if:
              someone at your company/organization designates you as a contact
              person for that company/organization or includes information about
              you in proposal documents; another visitor includes it in any
              feedback, comments, photos, videos, or other information submitted
              via online portals, electronic forms, surveys, or interactive
              portions of our sites; or one of our employees or service
              providers provides or a third party acting on apparent authority
              provides it to us when registering you to access our facilities or
              our sites, apply for a job, or participate in one of our events.
            </p>
            <h3>
              Data we collect automatically, including cookies, pixels, and
              similar technologies
            </h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may collect some personal data
              automatically. For example, when you visit our sites, we may
              collect your Internet Protocol (&quot;IP&quot;) address, Internet
              service provider (&quot;ISP&quot;) information, and browser type
              and language. We also may use cookies, pixels, and similar
              technologies to collect data about your interaction with our
              sites, including, for example, referring webpage, pages visited on
              our sites, and crash data. In addition, we may link the
              information we collect automatically or the information from any
              cookie or pixel with the information you provide in other contexts
              on our sites (newsletters, etc.) to personalize, connect and
              streamline your experience when visiting our sites. Please see
              Cookies & Similar Technologies for more information, including how
              to manage cookies, pixels, and similar technologies.
            </p>
            <h3>Minors</h3>
            <p>
              Our sites are not intended for minors (individuals under the age
              of 13, or equivalent minimum age depending on jurisdiction), and
              we do not knowingly collect personal data from minors. We do not
              knowingly share personal data from minors aged 13, 14 or 15
              without opt in consent or under age 13 without parental consent.
              If you become aware of any personal data we have collected from a
              minor, please contact us. If we learn that we have collected
              personal data from a minor, we will take steps to delete the data
              without notice as soon as possible.
            </p>
            <h3>Other visitors to our sites</h3>
            <p>
              If you submit feedback, comments, photos, videos, or other
              information to interactive portions of our sites, such submission
              may be made publicly available to anyone who visits those areas of
              our sites. Other visitors may access, re-post, or use such
              submission. Even if you remove or delete your submission, copies
              may remain in cached or archived areas of our sites or retained by
              other visitors. Please use your discretion when submitting
              personal data in these contexts.
            </p>
            <h3>When we share your data</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors (including contingent workers,
              consultants, and out-sourced service providers) may share your
              personal data with our partners who have a business need to know
              to process it for us based on our instructions, and with partners
              that are collaborating with us to market, promote or provide
              products and services, conduct business activities or host events.
              We may share certain of your personal data with a third party for
              marketing or other valid business purposes unless you have opted
              out of having us do so. If you believe personal data you provided
              to us is being misused by a third party, please contact us right
              away.
            </p>
            <h3>
              Disclosure and &quot;Sale&quot; of Personal Information and the
              Right to Opt-Out
            </h3>
            <p>
              This section describes our personal information disclosure,
              sharing or sale practices and explains how a consumer can opt-out
              of the sale or disclosure of their personal information.
            </p>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors collect and share personal
              information collected from users of our web sites or other
              sources. This may contain information about consumers' business
              personas, including IP address, domain name(s), phone number,
              email address and other information. This information is shared
              with vendors of information so that they can assist to identify
              your employer or the company or entity on whose behalf you are
              accessing our sites for the purpose of business-to-business sales,
              marketing, recruiting and other business purposes.
            </p>
            <p>
              A consumer has the right to opt-out of the transfer or sale of
              that consumer's personal information by us by
              {" "}<a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal_optout"
              >
                submitting a request
              </a>{" "}
              not to sell your consumer data to the Privacy Officer by sending
              an email to infosys@intraprise.com. In order to submit a request,
              a consumer or an authorized agent will be required to demonstrate
              that such person has control of an email inbox associated with the
              consumer in question. If such person cannot, then we may be
              contacted regarding other means of verifying such person's
              identity or the authorization of a third party to exercise a
              consumer's rights on that consumer's behalf.
            </p>
            <p>
              In all or a portion of the preceding twelve (12) months, we have
              transferred or disclosed for a business purpose the following
              categories of personal information to ZoomInfo, Zoho, and/or other
              vendors, customers, partners, affiliates, or service providers:
            </p>
            <ul>
              <li>
                Identifiers such as IP address, name, industry, title, company
                name, phone number and email address;
              </li>
              <li>Internet or other similar network activity; and</li>
              <li>Other Personal information.</li>
            </ul>
            <p>
              For avoidance of doubt, the personal information disclosed and/or
              sold by us within the identified categories is limited to contact
              information related to a consumer as an employee of its employer.
              No sensitive personal information (i.e., Social Security number,
              passport number, medical or financial information) is collected,
              shared, disclosed, or sold by us.
            </p>
            <h3>Interest-based advertising</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may use third-party service
              providers to serve our advertisements across the Internet. These
              companies may use tracking technologies to collect information
              about your visits to our sites and other websites and your
              interaction with our advertising and other communications. Some
              advertisements may be personalized, meaning that they are intended
              to be relevant to you based on information collected about your
              visits to our sites and elsewhere over time. Other companies may
              also use such technology to advertise on our sites.
            </p>
            <p>
              You have the choice to tell us not to collect and use this
              information, and, in some jurisdictions, we will only engage in
              interest-based advertising if you opt-in. If you would like more
              information about this practice and to know your choices
              concerning interest-based advertisements, visit:
            </p>
            <p>
              <a
                href="http://www.networkadvertising.org/choices/"
                rel="noopener"
                target="_blank"
              >
                http://www.networkadvertising.org/choices/
              </a>
              <br />
              In Canada, please visit:{" "}
              <a
                href="http://youradchoices.ca/choices/"
                rel="noopener"
                target="_blank"
              >
                http://youradchoices.ca/choices/
              </a>
              <br />
              In the EU, please visit:{" "}
              <a
                href="http://www.youronlinechoices.eu/"
                rel="noopener"
                target="_blank"
              >
                http://www.youronlinechoices.eu/
              </a>
            </p>
            <h3>Law enforcement</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may share your personal data
              with law enforcement, other government agencies or authorities, or
              third parties as required by applicable law, court order,
              subpoena, or legal process served on us.
            </p>
            <h3>How do we protect your information?</h3>
            <p>
              As the transmission of data via the internet is not completely
              secure, we cannot guarantee the security of your information
              transmitted to our sites and any such transmission is at your own
              risk. However, to protect the privacy of personal information
              provided by you, we employ industry-standard controls and maintain
              appropriate technical and organizational measures, including
              performing regular self-assessments, to prevent unauthorized
              disclosure of, or access to, personal data. We limit access to
              personal data and require that employees authorized to access
              personal data maintain the confidentiality of that data.
              Additionally, only authorized administrators and staff have access
              to systems containing such information. We hold our service
              providers to at least the same data privacy and security standards
              to which we hold ourselves. We urge you to take every precaution
              to protect your personal data when you are on the Internet. Change
              your passwords often, a combination of letters and numbers, use
              dual authentication, limit time before automatic log out, make
              sure you use a secure browser, stay current on all applicable
              updates, use an industry standard antivirus software and take all
              other reasonable security and privacy precautions.
            </p>
            <h3>How we store your data</h3>
            <h4>Storage period</h4>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors will store your personal data
              until it is no longer needed to fulfill the purpose(s) for which
              it was collected or as otherwise required or permitted by law.
              After such time, we may either delete or anonymize your personal
              data or, if this is not possible, we may securely store your
              personal data and isolate it from any further use until deletion
              is possible. We may dispose of any data in our discretion without
              notice, subject to applicable law. Please contact us if you would
              like more details regarding our retention periods for different
              categories of personal data.
            </p>
            <h3>How you can access and control your data</h3>
            <h4>Access and control</h4>
            <p>
              If you wish to obtain confirmation that we hold personal data
              about you, access, correct, or delete your personal data, withdraw
              any consent you previously provided to us, or object to or
              restrict our processing of your personal data in any other
              context, please contact us. We will exercise commercially
              reasonable efforts to respond to all legitimate requests within 45
              days.
            </p>
            <p>
              If you do not wish to receive promotional communications from us,
              you can opt-out by following the instructions contained in the
              messages you receive. Even if you opt-out of receiving these
              promotional communications, we reserve the right to send you
              certain communications relating to our sites, including
              administrative messages. We do not offer you the opportunity to
              opt-out of receiving those communications. For more information
              about interest-based advertising, including how you can manage
              advertising, please see above &quot;Interest-based
              advertising.&quot;
            </p>
            <section id="cookies" className="my-0 py-0">
              <h3>Use of Cookies & Similar Technologies</h3>

              <p>
                Intraprise may use cookies and similar technologies to: (1) help
                us provide better customer service and to enhance the user
                experience and deliver personalized content. Many improvements
                and updates to this website, such as providing you with tailored
                content, a personalized experience, and maximum site utilization
                are based on the data collected through the cookies, such as the
                total number of visitors and pages viewed; (2) allow you to use
                the sites without having to re-enter your user name and
                password; (3) understand how you engage with the sites and to
                enhance or personalize your experience, including across
                devices; (4) monitor the site usage; (5) manage the sites; (6)
                improve the sites and our services, including providing you with
                interest-based ads; (7) track unique visits; (8) prevent bot
                attacks; (9) orchestrate the rate limiting rules; (10) identify
                recurring visitors; and (11) other purposes. For more
                information on our advertising, see above: “Interest-based
                advertising.".
              </p>

              <h3>Managing Cookies</h3>

              <p>
                If the site has a &quot;Manage Cookies&quot; banner or button,
                indicating why the website needs to store cookies, identifies
                the cookies it plans to use and how the user can decline to
                consent to these cookies, you can view, change and manage your
                cookie settings at any time. By clicking &quot;Accept All,&quot;
                or &quot;Required Only&quot;, you consent to the placement of
                these additional cookies and similar technologies.{" "}
              </p>

              <p>
                If the site does not have a &quot;Manage Cookies&quot; banner or
                button, your continued use of the site represents your consent
                to the relevant cookies and similar technologies being placed on
                your computer or device.
              </p>

              <p>
                You do not need to have cookies enabled to browse our sites
                unless you want us to remember you and your preferences when you
                return. If you prefer not to allow cookies, most cookies can be
                managed or blocked through your browser. However, if your
                browser is set to reject cookies or you manually delete cookies,
                you may have some trouble accessing and using some of the pages
                and features that are currently on our sites, or that we may put
                on our sites in the future. Note that browser-management tools
                for cookies are outside of our control and we cannot guarantee
                their effectiveness. More information about managing cookies is
                available from your browser provider.
              </p>

              <p>
                There is no standard for how online service should respond to
                &quot;Do Not Track&quot; signals or other mechanisms that may
                allow you to opt out of the collection of information across
                networks of websites and online services. Therefore, we do not
                honor &quot;Do Not Track&quot; signals. As standards develop, we
                will revisit this issue and update this notice if our practices
                change. More information about Do Not Track is available at{" "}
                <a
                  href="https://allaboutdnt.com/"
                  rel="noopener"
                  target="_blank"
                >
                  www.allaboutdnt.org
                </a>
                .
              </p>
            </section>
            <h3>Web Beacons</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors may also use web beacons on our
              sites, in our emails, and in our advertisements on other websites.
              Web beacons are tiny graphic images that are used to collect
              information about your visit to our sites, such as the pages you
              view and the features you use, as well as information about
              whether you open and/or act upon one of our emails or
              advertisements. We may also collect the URL of the website you
              visited immediately before coming to our sites. Web beacons help
              us analyze our site visitors' behavior and measure the
              effectiveness of our sites and our advertising. We may work with
              service providers that help us track, collect and analyze this
              information.
            </p>
            <h3>Click-through URLs</h3>
            <p>
              If you &quot;opt in&quot; to receive newsletters, updates, or
              other information from us, our emails may use a
              &quot;click-through URL&quot; linked to content on our sites. When
              you click one of these URLs, they pass through a separate web
              server before arriving at the destination page on our sites. We
              use this click-through data to help us understand how recipients
              respond to, or interact with, our emails.
            </p>
            <h3>Third-party analytics services</h3>
            <p>
              We and our employees, agents, affiliates, vendors, service
              providers, partners or contractors use third-party analytics
              services, including ZoomInfo, Google Analytics and others to
              collect information about your use of our sites and enable us to
              improve our sites. You can learn about ZoomInfo by going to{" "}
              <a
                href="https://www.zoominfo.com/about-zoominfo/privacy-policy"
                target="_blank"
                rel="noopener"
              >
                https://www.zoominfo.com/about-zoominfo/privacy-policy
              </a>{" "}
              and Google's practices by going to
              www.google.com/policies/privacy/partners. ZoomInfo, Google
              Analytics and other third-party data or analytics services use
              cookies and similar technologies to collect information about use
              of our sites and to report website trends to us, without
              identifying individuals to us. We use this information to see the
              overall patterns of usage on our sites, help us record any
              difficulties you have with our sites, and tell us whether our
              communication efforts are effective. We may also link the
              information provided by third party analytics services with the
              information we collect, or you provide in other contexts on our
              site (newsletters, etc.), to personalize, connect and streamline
              your experience when visiting our sites or receiving
              communications from us.
            </p>
            <h3>Social media and video sites</h3>
            <p>
              If you choose to share our digital content with friends through
              social networks, such as Facebook and Twitter, or to watch a video
              posted to a third-party media site (such as YouTube), you may be
              sent cookies from these third-party websites. We do not control
              the setting of these cookies, so please check the third-party
              websites for more information about their cookies and how to
              manage them.
            </p>
            <h3>Exercise Your CCPA/CPRA Rights</h3>
            <p>
              If you are a California resident and the California Consumer
              Privacy Act (CCPA/CPRA) otherwise applies and gives you certain
              rights regarding your personal information, your CCPA/CPRA rights
              would include:
            </p>
            <ul>
              <li>
                Access: you can request identification of and access to personal
                information we've collected, shared or sold about you
              </li>
              <li>
                Deletion: you can ask us to delete personal information we've
                collected from you
              </li>
              <li>
                Correction of Inaccurate Information: you can ask us to correct
                inaccurate personal information we've collected from/about you
              </li>
              <li>
                Opt-out from sales: you can opt out of &quot;sales&quot; or
                sharing of personal information about you
              </li>
              <li>
                Non-retaliation: you have the right to not face retaliation for
                exercising the above rights
              </li>
            </ul>
            <p>
              You can submit your CCPA/CPRA requests by using the web form below
              or by calling us, at 267-274-2290, Monday through Friday from 9:00
              am until 5:00 pm Eastern time. Intraprise is closed on Saturdays,
              Sundays, and Intraprise holidays.
            </p>
            <p>
              If you want to exercise more than one of these CCPA/CPRA rights,
              please make a separate submission for each CCPA/CPRA request type,
              which will help us handle your requests more effectively. Please
              be aware that we will process your requests in the order we
              receive them.
            </p>
            <p>
              Whether you use the web form or call us, we will need information
              from you to confirm you are a California resident, to determine if
              we have personal information about you, and, for access and
              deletion requests, to verify your identity. We may also need to
              contact you about your request, and you can choose the contact
              method you would like us to use.
            </p>
            <p>
              Please be aware there might be circumstances where we will not
              take the action you requested. For example, if we're unable to
              verify your identity and confirm that you are a California
              resident, we may deny your access or deletion request, as
              permitted by the CCPA/CPRA. In addition, we may fully or partially
              deny your deletion request if CCPA/CPRA-recognized exceptions or
              exemptions apply. Please see our Privacy Policy for more
              information.
            </p>
            <p>
              If you want an authorized agent to submit a CCPA/CPRA request on
              your behalf, the authorized agent must provide proof of your
              written permission to submit the request(s) on your behalf and
              other information relating to their authorized agent status. If
              the authorized agent is submitting a “Delete My PI” or “Access My
              PI” request on your behalf, we may also require you to verify your
              identity directly with us.
            </p>
            <h3>Terms of Use</h3>
            <p>
              Please also visit our <Link to="/terms-of-use">Terms of Use</Link> section
              establishing the use, disclaimers, and limitations of liability governing
              the use of our website.
            </p>
            <h3>Passwords</h3>
            <p>
              You are responsible for maintaining the confidentiality of any
              password(s) you are given to access this website, and are fully
              responsible for all activities that occur under your password(s).
              You agree to notify Intraprise immediately of any unauthorized use
              of your password(s).
            </p>
            <h3>Termination of Use</h3>
            <p>
              You agree that Intraprise may, in its sole discretion, at any time
              terminate your access to this website and any account(s) you may
              have established in connection with this website. Access to this
              website may be monitored by Intraprise.
            </p>
            <h3>Applicable Law</h3>
            <p>
              All matters relating to your access to, and use of, this website
              will be governed by U.S. federal law or the laws of the
              Commonwealth of Pennsylvania. Any legal action or proceeding
              relating to your access to, or use of, this website will be
              instituted in a state or federal court with jurisdiction over
              Bucks County, Pennsylvania. You and Intraprise agree to submit to
              the jurisdiction of, and agree that venue is proper in, these
              courts in any such legal action or proceeding.
            </p>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default PrivacyPolicy;
