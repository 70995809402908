import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../assets/img/favicon.png';


const Head = ({ pageTitle, title, path, description, imageUrl, type, datePublished }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl,
                    title,
                    author
                }
            }
        }
    `);

    // remove html and double quotes  from description
    const stripHTMLregex = /(<([^>]+)>)/ig;
    const multiSpaceRegex = /\s\s+/g;
    const stripDblQuoteRegex = /["]+/g;
    const cleanDescription = description.replace(stripHTMLregex, " ").replace(multiSpaceRegex, " ").trim().replace(stripDblQuoteRegex, "");

    // convert date to iso
    //const publishDate = new Date(datePublished);
    //const isoDateStr = publishDate.toISOString();

    return (
        <>
            <Helmet title={`${pageTitle} | ${data.site.siteMetadata.title}`}
            bodyAttributes={{class: 'd-flex flex-column h-100'}}/>
            <Helmet>
                <link rel="icon" href={favicon} />
​
                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={cleanDescription}></meta>
                <meta name="twitter:image" content={imageUrl}></meta>
​
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content={data.site.siteMetadata.title} />
                <meta property="og:title" content={title}></meta>
                <meta property="og:url" content={data.site.siteMetadata.siteUrl + path}></meta>
                <meta property="og:description" content={cleanDescription}></meta>
                <meta property="og:image" content={imageUrl}></meta>
                <meta property="og:type" content={type} />
                <meta name="Description" content={cleanDescription}></meta>
                {/*
                <script type="text/javascript" src="https://cdn.cookielaw.org/consent/9bdc7775-542e-4f4d-98a3-a07e2a89817f-test/OtAutoBlock.js" ></script>
                <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="9bdc7775-542e-4f4d-98a3-a07e2a89817f-test" ></script>
                */}
				<script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/8fb8a3e6a403fdca06b86108/script.js"></script>
            </Helmet>
        </>
    )
}

export default Head